<script setup>
defineOptions({
    inheritAttrs: false,
});
/**
 * An array of options.
 *
 * @typedef {Array[]} OptionsArray
 * @property {string} label - The label name for the option.
 * @property {string} value - The unique value for the option.
 */

const { t } = useI18n({
    useScope: 'global',
});

/** EXAMPLE */
// const options = [
//     {
//         label: 'Option 1',
//         value: 'option-1'
//     }
// ];
const attrs = useAttrs();
const emit = defineEmits(['update:modelValue', 'change']);
const props = defineProps({
    modelValue: [String, Number, Boolean],
    placeholder: String,
    disclaimer: String,
    label: String,
    labelClass: String,
    inputClass: String,
    optionClass: String,
    wrapperClass: String,
    disabled: Boolean,
    options: { type: Array, required: true },
    error: [String, Boolean],
    nullable: Boolean,
    autocomplete: {
        type: String,
        default: 'off',
        // Doc - autocomplete attr.: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
    },
});

const placeholder = computed(() => {
    return props.placeholder ?? t('global.please_choose');
});

const selected = computed({
    get: () => props.modelValue,
    set: (v) => {
        emit('update:modelValue', v);
        emit('change', v);
    },
});

onMounted(() => {
    if (props.modelValue) {
        emit('update:modelValue', props.modelValue);
    }
});

// const filteredAttrs = computed(() => {
//     return { placeholder: _, ...attrs };
// });
</script>

<template>
    <div
        class="form-control-wrapper"
        :class="[{ 'has-error': error }, attrs.class, wrapperClass]"
    >
        <slot name="label">
            <label
                v-if="label"
                :class="['form-control-label', labelClass]"
                :for="attrs.id ?? attrs.name"
                v-text="label"
            />
        </slot>
        <select
            v-model="selected"
            :class="['form-select form-control', inputClass]"
            v-bind="attrs"
            :disabled="disabled"
            :autocomplete="autocomplete"
        >
            <option
                v-if="!!placeholder"
                :value="null"
                selected
                :disabled="!nullable"
                v-text="placeholder"
            />
            <option
                v-for="(option, index) in options"
                :key="`${attrs.name}-${index}`"
                :class="optionClass"
                :value="option.value"
                :disabled="option.disabled"
            >
                {{ option.label }}
            </option>
        </select>

        <slot name="disclaimer">
            <span
                v-if="disclaimer"
                class="text-xs text-gray-600 mt-2 pr-6 leading-tight"
                v-text="disclaimer"
            />
        </slot>

        <InputErrorMessage
            :error="error"
            :field-label="label"
            :field-name="attrs.name"
        />
    </div>
</template>
